import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import bg from "../images/legal-bg.jpg"
import enkeli from "../images/enkeli.jpg"

const Säädökset = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry -Säädökset" />
    <div className="hero-area full-container">
      <div
        className="page-banner page-banner-image parallax"
        style={{ background: `url(${bg})` }}
      >
        <div className="container">
          <h1>Säädökset</h1>
        </div>
      </div>
    </div>

    <div id="content" className="content full pt-10 pb-10">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-8 col-md-offset-2">
            <img
              className="pull-right padding-l25"
              style={{ width: "300px" }}
              src={enkeli}
              alt=""
            />
            <h2>Kotimaiset säädökset</h2>
            <h3>AVATUN HAUDAN EETTISET OHJEET</h3>
            <ul>
              <li>Kunnan terveydensuojeluviranomaiset</li>
              <li>Lääninhallitus</li>
            </ul>

            <ul>
              <li>TERVEYDENSUOJELULAKI 763/94</li>
              <li>Terveydensuojeluasetus 1280/94</li>
              <li>HAUTAUSTOIMILAKI 457/03</li>
            </ul>
            <h3>TERVEYDENSUOJELULAKI 763/94</h3>
            <ul>
              <li>
                13§ Ilmoitusvelvollisuus; ”hyvissä ajoin ennen toiminnan
                aloittamista…”
              </li>

              <li>
                1. mom. 6. kohta sellaisen laitoksen tai toiminnan
                aloittamisesta tai oleellisesta muuttamisesta, jonka
                hygieenisille olosuhteille… tai toiminnan luonne huomioon ottaen
                asetettava erityisiä velvoitteita
              </li>

              <li>
                42§ Hautausmaat ja hautaaminen; jos terveyshaittaa
                ts-viranomainen voi asettaa velvoitteita epäkohdan poistamiseksi
                tai kieltää hautaamisen.
              </li>
            </ul>
            <h3>TERVEYDENSUOJELUASETUS 1 280/94</h3>
            <ul>
              <li>
                3§ 5. kohta Ilmoitusvelvollisuus; hautausmaa tai hautapaikka
                Huom! muutokset esim. alueessa
              </li>
              <li>7 luku</li>
              <li>
                40§ arkkuhautaus viivytyksettä vähintään 150 cm:n syvyyteen.
                Samaa arkkuhautauspaikkaa saa käyttää aikaisintaan 15 v
                kuluttua. Hautakammio mahdollinen.
              </li>
              <li>
                41§ tarttuvat taudit kst:n ilmoitettava ruumiin käsittelijälle.
              </li>
              <li>
                42§ haudatun ruumiin siirtäminen. Hakemus lähtöpaikan terv.suoj.
                -viranomaiselle; kuolemansyy, aika, hautapaikka ja kohde.
                Mahdollinen terveyshaitta -&gt; kielteinen päätös tai ehtoja.
                Ts-edustajan oltava paikalla hautaa aukaistaessa ja uudelleen
                haudattaessa. Ilmoitukset kohdekuntaan.
              </li>
            </ul>
            <h3>HAUDAN AVAAMINEN</h3>
            <ul>
              <li>
                40§ arkkuhautaus viivytyksettä vähintään 150 cm:n syvyyteen.
                Samaa arkkuhautauspaikkaa saa käyttää aikaisintaan 15 v
                kuluttua.
              </li>
              <li>Hautojen päällekkäisyys; ”syvähauta” –</li>

              <li>Hautakammio mahdollinen.</li>
            </ul>
            <h3>HAUDAN PEITTÄMINEN</h3>
            <ul>
              <li>
                Hauta pyrittävä peittämään viipymättä, tiiviissä asianmukaisessa
                arkussa tai vast. TsA 40§.
              </li>
              <li>Perjantaihautausten osalta peitto viimeistään lauantaina.</li>
              <li>
                Hautaus lauantaina; peittäminen mahdollisuuksien mukaan… Huom!
                luonnonolosuhteet ja vuodenaika; vesi yms.
              </li>
            </ul>
            <h3>HAUDAN PEITTÄMINEN II</h3>
            <ul>
              <li>
                Aiemmin haudatun vainajan jäännökset suojataan ja peitetään
                avatun haudan pohjalle
              </li>
              <li>Haudan koneellinen umpeen luominen</li>
              <li>
                Haudan tiivistäminen; tärylevy, kaivurin täryytin, vesi
                MIELIKUVAT TOIMISTA; VAINAJAN KUNNIOITTAMINEN!
              </li>
            </ul>
            <h3>VAINAJAN TAI TUHKAN SIIRTO</h3>
            <ul>
              <li>
                24§ Haudattu ruumis tai tuhka voidaan ao. lääninhallituksen
                luvalla erityisen painavilla perusteilla siirtää toiseen
                hautaan.
              </li>

              <li>
                Lääninhallituksiin tulee kyselyjä siirtomahdollisuudesta
                viikoittain -&gt; ainoastaan murtoosa hakee. Ohjaava neuvonta.
              </li>
              <li>
                Perusteet riittävät harvoin. AINA YKSILÖLLINEN HARKINTA!
                Erittäin painavaksi perusteluksi on riittänyt esim. inhimillinen
                virhe hautauksessa (tunnistus, tietokatkos, ..). Perusteeksi ei
                riitä hautauksen jälkeen syntyneet erimielisyydet..
                Sukuhautariidat, tietämättömyys hautaushetkellä yms. Kielteiset
                päätökset -&gt; hallinto-oikeus-KHO.
              </li>
              <li>
                Pro Gradu; HAUTAUS; Viimeisen matkan oikeudelliset ongelmat ja
                itsemääräämisoikeuden ulottuvuus, Lapin yliopisto,
                Oikeustiet.tiedek. 2007
              </li>
              <li>
                Lähtökohtana kaikessa hautausjärjestelyitä koskevassa
                päätöksenteossa on vainajan katsomuksen ja toivomusten
                kunnioittaminen. Hautaustoimilain myötä hautapaikka vainajan
                viimeisenä leposijana korostuu.
              </li>
              <li>
                Hakusanat: Hautaus, itsemääräämisoikeus, tuhkaus, lupamenettely,
                uskonnonvapaus
              </li>
            </ul>
            <div className="spacer-35" />
            <h2>EU Lainsäädöntö</h2>
            <p>
              <a href="doc/cremation_and_respect_for_the_environnement.pdf">
                Cremation and respect for the environment
              </a>
            </p>
          </div>
        </div>

        <div className="spacer-45" />
      </div>
    </div>
  </Layout>
)

export default Säädökset
